import React, {useEffect, useState} from 'react';
import style from './SumOtherPayment.module.css';
import axios from "axios";
import {Markup} from "interweave";
import {useTranslation} from "react-i18next";

const Loader = React.lazy(() => import("../../../../components/Loader/Loader"));
const News = React.lazy(() => import("../../Head/News/News"));

export default function SumOtherPayment() {
    const  { t } = useTranslation();
    const [getObjProcurement, ObjSetProcurement] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    let url = window.location.origin;

    if(url === "http://localhost:3000") {
        url = "https://d-mlsa.nork.am";
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        window.addEventListener('languageChange', (event)=>{
            event.preventDefault();
            axiosRequest();
        });
        axiosRequest();
    }, []);

    function  axiosRequest(){
        let language = localStorage.getItem('language');
        if(language === "hy"){
            language = "am";
        }
        const getData = async () => {
            await axios.get(url+"/api/sum_other_payments/"+language)
                .then((resProcurement) => {
                    const ProcurementData = resProcurement.data;
                    ObjSetProcurement(ProcurementData);
                })
                .catch((e) => {
                    setError(e);
                })
                .finally(() => setIsLoading(false));
        };
        return getData();
    }

    const getErrorView = () => {
        return (
            <div>
                Էջում առաջացել են խնդիրներ, խնդրում ենք փորձել մի փոքր ուշ
            </div>
        )
    }

    if (isLoading) return <Loader/>;

    return (
        <div className={style.Main}>
            {error && getErrorView()}
            {getObjProcurement.map((catBudget, valueBudget) => (
                <div key={valueBudget} className={style.MinisterBlock}>
                    <div className={style.MinisterImg}>
                        <h1>{t('SumOtherPayment')}</h1>
                        <div className={style.MinImg}>
                            <img src={catBudget.image} alt=""/>
                        </div>
                    </div>
                    <div className={style.MinisterText}>
                        <Markup content={catBudget.description}/>
                    </div>
                </div>
            ))}
            <News/>
        </div>
    );
}