import React, { createContext, useState, useContext, useEffect, useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageContext = createContext();

// Custom hook to force re-render
const useForceUpdate = () => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    return forceUpdate;
};

export const LanguageProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'hy');
    const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
    const forceUpdate = useForceUpdate();

    const changeLanguage = useCallback(async (newLanguage) => {
        setIsLanguageLoaded(false);
        await i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
        setIsLanguageLoaded(true);
        forceUpdate(); // Force update after language change
    }, [i18n, forceUpdate]);

    useEffect(() => {
        changeLanguage(language);
    }, []);

    useEffect(() => {
        const handleLanguageChanged = () => {
            forceUpdate();
        };

        i18n.on('languageChanged', handleLanguageChanged);

        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [i18n, forceUpdate]);

    if (!isLanguageLoaded) {
        return <div>Loading...</div>; // Or any loading indicator
    }

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);

// Helper hook to subscribe to language changes
export const useLanguageChange = () => {
    const { language } = useLanguage();
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        forceUpdate();
    }, [language, forceUpdate]);

    return language;
};