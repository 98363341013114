// NavbarContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useLanguage } from '../Language/LanguageContext';

const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {
    const [navbarData, setNavbarData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { language } = useLanguage();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const url =
                    window.location.origin === "http://localhost:3000"
                        ? "https://t-mlsa.nork.am"
                        : window.location.origin;
                const response = await axios.get(`${url}/api/menu/${language === 'hy' ? 'am' : language}`);
                if (response.data && response.data.length > 0) {
                    setNavbarData(Object.values(response.data[0]));
                } else {
                    throw new Error('Invalid data structure received from API');
                }
            } catch (error) {
                console.error('Error fetching navbar data:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [language]);

    return (
        <NavbarContext.Provider value={{ navbarData, setNavbarData, isLoading, error }}>
            {children}
        </NavbarContext.Provider>
    );
};

export const useNavbar = () => {
    const context = useContext(NavbarContext);
    if (context === undefined) {
        throw new Error('useNavbar must be used within a NavbarProvider');
    }
    return context;
};
